import React, { useState } from 'react';
import { css } from '@emotion/react';
import LogoBVN from '../svg/logo.svg';
import ConditionalWrapper from './conditional-wrapper';
import Link from './link';

export default function Logo({ className, url, invert, updateHoverColor }) {
  return (
    <ConditionalWrapper
      condition={url}
      wrapper={(children) => (
        <Link className={className} onMouseOut={updateHoverColor} to={url}>
          {children}
        </Link>
      )}
    >
      <LogoBVN
        className={url ? '' : className}
        css={css`
          display: block;
          width: 100%;
          height: auto;

          path {
            ${invert && 'fill: var(--white);'}
          }
        `}
      />
    </ConditionalWrapper>
  );
}
