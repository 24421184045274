import React, { useContext } from 'react';
import { css } from '@emotion/react';
import ArrowNE from '../svg/arrow-n-e.svg';

export default function LinkOutBox({ title, ...props }) {
  return (
    <div
      {...props}
      css={css`
        padding: 0.9rem 1.6rem 0.8rem 1.4rem;
        background-color: var(--black);
      `}
    >
      {title && (
        <span 
          className="type--mono"
          css={css`
            color: var(--white);
            margin-right: 1.1rem;
          `}
        >{title}</span>
      )}
      <ArrowNE
        css={css`
          path {
            fill: var(--white);
          }
        `}
      />
    </div>
  );
}