import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import useEmblaCarousel from 'embla-carousel-react';
import SanityImage from 'gatsby-plugin-sanity-image';
import { Close, Dot } from './icons';
import BlockContent from './block-content';

export default function PopupGallery({
  className,
  closePosition,
  invert,
  images,
  text,
  closeOnClick,
  displayPopup,
}) {
  const [emblaRef, embla] = useEmblaCarousel({ loop: true });
  const [current, setCurrent] = useState(0);
  images = (images) ? images : [];
  const ratio = images[0]?.asset.metadata.dimensions.aspectRatio;

  embla &&
    embla.on('select', () => {
      setCurrent(embla.slidesInView(true)[0]);
    });

  useEffect(() => {
    embla?.reInit({ loop: true });
  }, [displayPopup]);

  return (
    <div
      className={className}
      css={css`
        position: relative;
        width: 38.7rem;
        max-width: 100%;
        padding: 3rem 3.5rem;
        background-color: ${invert ? 'var(--black)' : 'var(--white)'};
        border: 4px solid var(--black);
        filter: var(--dropShadow);

        @media (max-width: 800px) {
          width: 35rem;
          padding: var(--gutterHalf);
        }
      `}
    >
      {images.length > 1 && (
        <>
          <div
            className="embla"
            ref={emblaRef}
            css={css`
              width: 100%;
              overflow: hidden;
              margin-bottom: 1.8rem;
            `}
          >
            <div
              className="embla__container"
              css={css`
                display: flex;
              `}
            >
              {images.map((image, i) => {
                return (
                  <div
                    key={i}
                    css={css`
                      position: relative;
                      display: flex;
                      flex: 0 0 100%;
                      align-items: center;
                      justify-content: center;

                      &:first-of-type img {
                        object-fit: fill !important;
                      }
                    `}
                  >
                    <SanityImage
                      {...image}
                      width={660}
                      loading="eager"
                      style={{
                        display: 'block',
                        flexBasis: '100%',
                        maxWidth: '100%',
                        aspectRatio: `${ratio} / 1`,
                        objectFit: 'contain',
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 3.6rem;

              @media (max-width: 800px) {
                margin-bottom: 2.2rem;
              }
            `}
          >
            {images.map((image, i) => {
              return (
                <Dot
                  key={i}
                  mini={true}
                  css={css`
                    display: inline-block;
                    margin-right: 1.4rem;

                    &:last-child {
                      margin-right: 0;
                    }

                    &:hover {
                      svg circle {
                        fill: var(--black) !important;
                      }
                    }
                  `}
                  invert={invert}
                  active={i === current ? true : false}
                  onClick={() => embla?.scrollTo(i)}
                />
              );
            })}
          </div>
        </>
      )}
      {images.length === 1 &&
        images.map((image, i) => {
          return (
            <SanityImage
              key={i}
              {...image}
              width={660}
              loading="eager"
              style={{
                display: 'block',
                width: '100%',
                maxWidth: '100%',
                marginBottom: '3.6rem',
              }}
            />
          );
        })}
      <div
        className="type--body-small"
        css={css`
          color: ${invert ? 'var(--white)' : 'var(--black)'};
        `}
      >
        <BlockContent content={text} />
      </div>
      <div
        css={css`
          position: absolute;
          top: 0;
          left: ${closePosition ? 'auto' : '0'};
          right: ${closePosition ? '0' : 'auto'};
          transform: translate(${closePosition ? '50%' : '-50%'}, -50%);
        `}
      >
        <Close active={true} hover={true} onClick={closeOnClick} />
      </div>
    </div>
  );
}
