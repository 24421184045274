import React, { useState } from 'react';
import { css } from '@emotion/react';
import Pill from './pill';

export default function PillDropdown({
  className,
  size,
  hover,
  background,
  items,
  onClick,
}) {
  const [displayDropdown, setDisplayDropdown] = useState(false);

  return (
    <div
      className={className}
      css={css`
        position: relative;
      `}
      onClick={() => setDisplayDropdown(false)}
      onMouseLeave={() => setDisplayDropdown(false)}
    >
      <Pill
        size={size}
        text={items[0].text}
        url={items[0].url}
        active={displayDropdown}
        background={background}
        onClick={onClick}
        onMouseEnter={() => setDisplayDropdown(true)}
        css={css`
          &:hover div {
            color: var(--white);
            background-color: var(--black);
          }
        `}
      />
      <div
        css={css`
          position: absolute;
          top: 100%;
          left: 50%;
          text-align: center;
          opacity: ${displayDropdown ? 1 : 0};
          pointer-events: ${displayDropdown ? 'auto' : 'none'};
          transform: translateX(-50%);
          transition: opacity 0.3s var(--curve);
        `}
      >
        {items &&
          items.slice(1).map((item, i) => {
            return (
              <Pill 
                key={item.text}
                size={size}
                text={item.text}
                url={item.url}
                hover={hover}
                background={background}
                onClick={onClick}
                css={css`
                  margin-top: 1rem;
                `}
              />
            );  
          })
        }
      </div>
    </div>
  );
}
