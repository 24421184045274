import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { useFlexSearch } from 'react-use-flexsearch';
import { css } from '@emotion/react';
import { format } from 'date-fns';
import { FilterButton } from './icons';
import Box from './box';
import Grid from './grid';
import Card from './card';
import PeopleCard from './people-card';
import { colors } from './colors';

export default function SearchBar({
  className,
  onClick,
  filterButtonOnClick,
  searchTerm,
  resultCount,
  placeholder,
  setSearchOpen,
  setMenuOpen,
  inputRef,
}) {
  const [query, setQuery] = useState('');
  const [searchInput, setSearchInput] = useState(searchTerm);
  const [resultItems, setResultItems] = useState([]);

  const data = useStaticQuery(graphql`
    query SearchQuery {
      siteSettings: sanitySiteSettings(_id: { regex: "/(drafts\\\\.)?siteSettings/" }) {
        siteDescription
        siteTitle
        siteSocialImage {
          asset {
            _id
            gatsbyImageData(width: 1980)
          }
        }
      }
      searchAll: localSearchAll {
        index
        store
      }
      projects: allSanityProject(
        filter: { slug: { current: { ne: null } } }
      ) {
        nodes {
          _id
          title
          slug {
            current
          }
          featuredImage {
            ...Image
          }
          discipline {
            title
            slug {
              current
            }
          }
          comingSoon
        }
      }
      articles: allSanityArticle(
        filter: { slug: { current: { ne: null } } }
      ) {
        nodes {
          _id
          title
          slug {
            current
          }
          externalArticle
          url
          publication
          datetime
          featuredImage {
            ...Image
          }
          tag {
            title
            slug {
              current
            }
          }
        }
      }
      people: allSanityPerson(filter: { active: { eq: true } }) {
        nodes {
          _id
          name
          slug {
            current
          }
          singlePage
          image {
            ...Image
          }
          position
          role {
            title
            slug {
              current
            }
          }
          office {
            title
            slug {
              current
            }
          }
        }
      }
    }
  `);

  const results = useFlexSearch(query, data.searchAll.index, data.searchAll.store);

  function handleInputChange(e) {
    setSearchInput(e.target.value);
  }

  return (
    <div
      className={className}
      onClick={onClick}
    >
      <div
        className="type--heading-four"
        css={css`
          display: flex;
          margin-bottom: 3.4rem;
        `}
      >
        <input
          ref={inputRef}
          type="search"
          name="search"
          placeholder={placeholder ? placeholder : 'What are you searching for?'}
          value={`${searchInput ? searchInput : ''}`}
          css={css`
            flex-grow: 1;
            line-height: 1;
            padding: 1rem 0 1.7rem;
            border: none;
            border-bottom: 4px solid;
            border-radius: 0;
            border-color: var(--black) !important;

            @media (max-width: 350px) {
              overflow-x: hidden;
            }

            &::-webkit-search-decoration,
            &::-webkit-search-cancel-button,
            &::-webkit-search-results-button,
            &::-webkit-search-results-decoration {
              display: none;
            }
          `}
          onChange={handleInputChange}
          onKeyUp={(e) => {
            e.persist();
            onClick();
            setQuery(e.target.value);
          }}
        />
      </div>
      {results.length > 0 && (
        <div
          className="type--mono-heading"
          css={css`
            text-align: right;
          `}
        >
          {results.length} results
        </div>
      )}
      <Box y={6}>
        {query.length > 0 && results.length === 0 && (
          <Grid columns={6}>
            <div
              className="type--heading-four"
              css={css`
                text-align: center;
                padding-top: var(--margin10);
              `}
            >
              <p>Your search did not return any results.</p>
            </div>
          </Grid>
        )}
        {results.length > 0 && (
          <Grid
            css={css`
              > div,
              a {
                grid-column-start: span 4;

                @media (max-width: 1024px) {
                  grid-column-start: span 6;
                }

                @media (max-width: 550px) {
                  grid-column-start: span 8;
                }

                img {
                  aspect-ratio: 387/380;
                }
              }
            `}
          >
            {results.map((result) => {
              if (data.projects.nodes.find((node) => node._id === result._id)) {
                const project = data.projects.nodes.find((node) => node._id === result._id);

                return (
                  <div
                    onClick={() => {
                      setSearchOpen(false);
                      setMenuOpen(false);
                    }}
                  >
                    <Card
                      url={project.comingSoon ? null : `/project/${project.slug.current}`}
                      image={project.featuredImage}
                      imageCrop={true}
                      monoText={project.discipline
                        .map((discipline) => discipline.title)
                        .sort()
                        .join(', ')}
                      text={project.title}
                      hoverText={project.comingSoon ? <div>Case study coming soon</div> : null}
                    />
                  </div>
                );
              } else if (data.articles.nodes.find((node) => node._id === result._id)) {
                const article = data.articles.nodes.find((node) => node._id === result._id);

                return (
                  <div
                    onClick={() => {
                      setSearchOpen(false);
                      setMenuOpen(false);
                    }}
                  >
                    <Card
                      url={article.url ? article.url : `/article/${article.slug.current}`}
                      external={article.externalArticle}
                      externalTitle={article.publication}
                      image={article.featuredImage}
                      imageCrop={true}
                      monoText={`${article.tag
                        .map((tag) => tag.title)
                        .sort()
                        .join(', ')} — ${format(new Date(article.datetime), 'MMMM d, yyyy')}`}
                      text={article.title}
                    />
                  </div>
                );
              } else if (data.people.nodes.find((node) => node._id === result._id)) {
                const person = data.people.nodes.find((node) => node._id === result._id);

                return (
                  <div
                    onClick={() => {
                      setSearchOpen(false);
                      setMenuOpen(false);
                    }}
                  >
                    <PeopleCard
                      url={person.singlePage ? `/person/${person.slug.current}` : null}
                      image={person.image ? person.image : null}
                      text={`${person.name}${person.position ? `, ${person.position}` : ''}`}
                      css={css`
                        width: 100%;

                        > div > div:first-of-type {
                          background-color: ${colors[person.name?.length % colors.length]};
                          padding-top: ${person.image ? '0' : '98.19%'};
                        }
                      `}
                    />
                  </div>
                );
              } else if (result._id === 'media') {
                return <Card
                  url="/media"
                  image={data.siteSettings.siteSocialImage}
                  imageCrop={true}
                  text="Here you’ll find downloadable press releases, images, info sheets and more."
                  monoText="Media"
                />
              }
            })}
          </Grid>
        )}
      </Box>
    </div>
  );
}
