import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import Form from './form';
import { randomColor, randomGoodColor } from './colors';

export default function SubscribeModal({ className }) {
  const [hide, setHide] = useState(true);
  const [buttonText, setButtonText] = useState('Submit');
  const [color, setColor] = useState(randomGoodColor());
  const [formValidBackground, setFormValidBackground] = useState(false);

  useEffect(() => {
    return init();
  }, []);

  function checkExpire() {
    const stored = localStorage.getItem('subscribe-shown');
    const tomorrow = new Date();

    tomorrow.setDate(tomorrow.getDate() + 1);

    if (stored) {
      if (new Date() > new Date(stored)) {
        localStorage.setItem('subscribe-shown', tomorrow);

        return true;
      } else {
        return false;
      }
    } else {
      localStorage.setItem('subscribe-shown', tomorrow);

      return true;
    }
  }

  function init() {
    if (checkExpire()) {
      const showTimer = setTimeout(() => {
        setHide(false);
      }, 1000);

      const handler = (e) => {
        setTimeout(() => {
          if (window.scrollY > window.innerHeight / 2) {
            setHide(true);
            clearTimeout(showTimer);
          }
        }, 1000);
      };

      document.addEventListener('scroll', handler);

      return () => window.removeEventListener('message', handler);
    }
  }

  async function onSubmit(e, data) {
    e.preventDefault();

    setButtonText('Subscribing...');

    const response = await window
      .fetch('/api/subscribe-email', {
        method: 'POST',
        body: data,
      })
      .then(res => res.json());

    setButtonText(response);
  }

  return (
    <div
      css={css`
        position: fixed;
        right: var(--margin4);
        bottom: var(--margin4);
        background-color: ${formValidBackground ? formValidBackground : 'white'};
        width: 550px;
        padding: var(--padding2);
        z-index: 100;
        filter: var(--dropShadow);
        opacity: ${(hide) ? 0 : 1};
        transform: translateY(${(hide) ? '-15px' : '0px'});
        pointer-events: ${(hide) ? 'none' : 'auto'};
        transition: opacity 0.3s var(--curve), transform 0.6s var(--curve);

        @media (max-width: 800px) {
          right: var(--margin2);
          bottom: var(--margin2);
          left: var(--margin2);
          width: auto;
        }
      `}
    >
      <div
        className="type--paragraph"
        css={css`
          margin-bottom: 1.5rem;

          @media (max-width: 550px) {
            margin-bottom: 3.5rem;
          }
        `}
      ><u>Curious to know more</u>? Sign up for Under the Hood. An irregular newsletter.</div>
      <form
        onChange={(e) => {
          if (e.target.form.checkValidity() && e.target.value.includes('.')) {
            !formValidBackground && setFormValidBackground(randomGoodColor());
          } else {
            setFormValidBackground(false);
          }
        }}
        onSubmit={(e) => onSubmit(e, new FormData(e.target))}
      >
        <input
          className="type--paragraph"
          css={css`
            width: 100%;
            color: var(--black);

            &[type='email'] {
              padding-right: 110px;
              border-width: 3px !important;
            }

            &:focus,
            &:focus:placeholder-shown {
              color: ${(formValidBackground) ? 'var(--black)' : color};
              border-color: ${(formValidBackground) ? 'var(--black)' : color};

              &::placeholder {
                color: var(--gray-light);
              }
            }
          `}
          type="email"
          name="email"
          placeholder="Enter your email here"
          onFocus={() => setColor(randomGoodColor())}
          required
        />
        <input
          className="type--button-small"
          type="submit"
          value={buttonText}
          css={css`
            position: absolute;
            right: var(--margin2);
            bottom: var(--margin4);
            font-weight: normal;
            color: var(--black);
            padding: 0.8rem 1.4rem 0.6rem;
            background-color: transparent;
            border: 3px solid var(--black);
            border-radius: 44rem;
            cursor: pointer;

            @media (max-width: 550px) {
              bottom: var(--margin3);
              border: 2px solid var(--black);
            }

            &:hover {
              color: var(--white);
              background-color: var(--black);
            }
          `}
        />
      </form>
    </div>
  );
}
