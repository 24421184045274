import React, { useState } from 'react';
import { css } from '@emotion/react';

export default function Box({ ref, className, x, y, children }) {
  let responsiveXTablet = [
    null,
    'var(--margin1)',
    'var(--gutterHalf)',
    'var(--gutter)',
    'var(--gutterHalf)',
  ];
  responsiveXTablet[10] = 'var(--gutter)'; // Works better with offset caption cards

  let responsiveXMobile = [
    null,
    'var(--margin1)',
    'var(--gutterHalf)',
    'var(--gutterHalf)',
    'var(--gutterHalf)',
  ];
  responsiveXMobile[10] = 'var(--gutterHalf)';

  const responsiveYTablet = [
    null,
    null,
    null,
    'var(--margin3)',
    'var(--gutter)',
    'var(--margin5)',
    'var(--margin4)', // 6
    'var(--margin7)',
    'var(--margin8)',
    'var(--margin5)',
    'var(--margin7)',
    'var(--margin11)',
    'var(--margin12)',
    'var(--margin11)', // 13
    'var(--margin14)',
    'var(--margin15)',
    'var(--margin12)', // 16
    'var(--margin17)',
    'var(--margin14)',
    'var(--margin19)',
    'var(--margin20)',
  ];

  const responsiveYMobile = [
    null,
    null,
    null,
    'var(--margin3)',
    'var(--gutter)',
    'var(--margin5)',
    'var(--margin3)', // 6
    'var(--margin7)',
    'var(--margin8)',
    'var(--margin5)',
    'var(--margin7)',
    'var(--margin11)',
    'var(--margin7)',
    'var(--margin9)', //13
    'var(--margin14)',
    'var(--margin15)',
    'var(--margin9)', // 16
    'var(--margin17)',
    'var(--margin)',
    'var(--margin19)',
    'var(--margin20)',
  ];

  return (
    <div
      ref={ref}
      className={className}
      css={css`
        padding: 0 ${x ? `var(--padding${x})` : '0'};
        margin: ${y ? `var(--margin${y})` : '0'} 0;

        @media (max-width: 1024px) {
          padding-right: ${x ? responsiveXTablet[x] : '0'};
          padding-left: ${x ? responsiveXTablet[x] : '0'};
        }

        @media (max-width: 800px) {
          margin: ${y ? responsiveYTablet[y] : '0'} 0;
        }

        @media (max-width: 550px) {
          padding: 0 ${x ? responsiveXMobile[x] : '0'};
          margin: ${y ? responsiveYMobile[y] : '0'} 0;
        }
      `}
    >
      {children}
    </div>
  );
}
