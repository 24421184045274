import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Close } from './icons';
import Pill from './pill';
import SocialShare from './social-share';

export default function PopupShare({ className, title, invert, swapClosePosition, closeOnClick, hash }) {
  const [browserDocument, setBrowserDocument] = useState();
  const [copyText, setCopyText] = useState('Copy link...');

  const white = invert ? 'var(--black)' : 'var(--white)';
  const black = invert ? 'var(--white)' : 'var(--black)';

  useEffect(() => {
    setBrowserDocument(document);
  }, []);
  return (
    <div
      className={`type--body ${className}`}
      css={css`
        position: absolute;
        top: 15rem;
        left: 50%;
        display: block;
        width: 71.9rem;
        max-width: calc(100% - var(--gutter));
        min-height: 42rem;
        color: ${white};
        text-align: center;
        background-color: ${black};
        padding: var(--margin5);
        border: 4px solid var(--black);
        filter: var(--dropShadow);
        transform: translateX(-50%);

        @media (max-width: 800px) {
          min-height: auto;
          padding: var(--gutter);
        }

        @media (max-width: 550px) {
          padding: 3rem var(--gutterHalf);
        }
      `}
    >
      <h6
        className="type--mono-heading"
        css={css`
          margin-bottom: var(--gutterHalf);
        `}
      >
        Share
      </h6>
      <h5
        className="type--heading-four type--underline"
        css={css`
          margin-bottom: var(--gutter);
        `}
      >
        {title ? title : 'BVN'}
      </h5>
      <div>
        <span>Share via:</span>
        <div
          css={css`
            display: flex;
            justify-content: center;
            margin-top: 1.3rem;
            margin-bottom: 3.4rem;
          `}
        >
          <SocialShare invert={invert} hash={hash} emailTitle={title ? title : 'BVN'} />
        </div>
        <span>Or</span>
        <br />
        <Pill
          size={2}
          text={copyText}
          hover={true}
          invert={!invert}
          css={css`
            margin-top: 1.3rem;
          `}
          onClick={() => {
            navigator.clipboard.writeText(window.location.href + hash);
            setCopyText('Copied!');
          }}
        />
      </div>
      <Close
        active={invert}
        css={css`
          position: absolute;
          top: 0;
          right: ${swapClosePosition ? '0' : 'auto'};
          left: ${swapClosePosition ? 'auto' : '0'};
          transform: translate(${swapClosePosition ? '50%' : '-50%'}, -50%);
        `}
        onClick={closeOnClick}
      />
    </div>
  );
}
