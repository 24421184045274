import React, { useState } from 'react';
import { css } from '@emotion/react';
import SanityImage from 'gatsby-plugin-sanity-image';
import { Close } from './icons';
import Pill from './pill';

export default function PopupImagePill({
  className,
  image,
  text,
  url,
  closePosition,
  closeOnClick,
}) {

  if (image.asset.metadata.lqip) 
    delete image.asset.metadata.lqip;

  return (
    <div
      className={className}
      css={css`
        position: relative;
        width: 31.2rem;
        max-width: 100%;
        border: 4px solid var(--black);
        filter: var(--dropShadow);

        @media (max-width: 800px) {
          width: 35rem;
        }
      `}
    >
      {image && (
        <SanityImage
          {...image}
          width={660}
          style={{
            display: 'block',
            width: '100%',
          }}
        />
      )}
      <Pill
        size={2}
        url={url}
        text={text}
        css={css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `}
      />
      <div
        css={css`
          position: absolute;
          top: 0;
          left: ${closePosition ? 'auto' : '0'};
          right: ${closePosition ? '0' : 'auto'};
          transform: translate(${closePosition ? '50%' : '-50%'}, -50%);
        `}
      >
        <Close active={true} hover={true} onClick={closeOnClick} />
      </div>
    </div>
  );
}
