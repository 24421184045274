import React, { useState } from 'react';
import { css } from '@emotion/react';
import SanityImage from 'gatsby-plugin-sanity-image';
import { Close, Right } from './icons';

export default function PopupImageTitleLinkButton({
  className,
  image,
  title,
  url,
  closePosition,
  closeOnClick,
}) {
  return (
    <div
      className={className}
      css={css`
        position: relative;
        width: 39.5rem;
        max-width: 100%;
        min-height: 40.8rem;
        background-color: var(--white);
        border: 4px solid var(--black);
        filter: var(--dropShadow);

        @media (max-width: 800px) {
          width: 35rem;
          min-height: auto;
        }
      `}
    >
      {image && (
        <SanityImage
          {...image}
          width={660}
          style={{
            display: 'block',
            width: '100%',
            aspectRatio: '9/6',
            objectFit: 'cover',
          }}
        />
      )}
      <div
        css={css`
          text-align: right;
          padding: 2.1rem 2.4rem 1.6rem 2.6rem;

          @media (max-width: 800px) {
            padding: 1.8rem var(--gutterHalf) 1.2rem;
          }
        `}
      >
        <div
          className="type--paragraph"
          css={css`
            text-align: left;
            margin-bottom: 2rem;

            @media (max-width: 550px) {
              margin-bottom: 1.2rem;
            }
          `}
        >
          {title}
        </div>
        <Right
          url={url}
          css={css`
            display: inline-block;
          `}
        />
      </div>
      <div
        css={css`
          position: absolute;
          top: 0;
          left: ${closePosition ? 'auto' : '0'};
          right: ${closePosition ? '0' : 'auto'};
          transform: translate(${closePosition ? '50%' : '-50%'}, -50%);
        `}
      >
        <Close active={true} hover={true} onClick={closeOnClick} />
      </div>
    </div>
  );
}
