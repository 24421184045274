import React, { useState } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { css } from '@emotion/react';
import BlockContent from './block-content';
import Logo from './logo';
import SocialButtons from './social-buttons';

export default function Footer() {
  const data = useStaticQuery(graphql`
    query FooterSettingsQuery {
      footerSettings: sanityFooterSettings(_id: { regex: "/(drafts\\\\.)?footerSettings/" }) {
        addresses {
          _rawText(resolveReferences: { maxDepth: 10 })
        }
        _rawLinks(resolveReferences: { maxDepth: 10 })
        _rawFollow(resolveReferences: { maxDepth: 10 })
        instagramUrl
        twitterUrl
        facebookUrl
        linkedInUrl
      }
    }
  `);

  return (
    <footer
      className="type--footer type--underline-inverse--parent"
      css={css`
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: 11.9rem var(--gutter);
        color: var(--white);
        background-color: var(--black);
        padding: 12.1rem var(--margin) 9.6rem;
        margin-top: -1px;

        @media (max-width: 1280px) {
          padding: 8rem var(--gutter) 5rem;
        }

        @media (max-width: 1024px) {
          padding: 6.4rem var(--margin3) 2.5rem;
          gap: 11.9rem var(--gutterHalf);
        }

        @media (max-width: 800px) {
          grid-template-columns: repeat(8, 1fr);
          gap: var(--gutter) var(--gutterHalf);
        }

        @media (max-width: 650px) {
          gap: var(--margin3) var(--margin1);
        }

        @media (max-width: 550px) {
          gap: var(--margin3) var(--margin1);
          padding-right: var(--gutterHalf);
          padding-left: var(--gutterHalf);
        }

        a,
        a:visited {
          color: var(--white);
          text-decoration: none;
        }

        div,
        nav {
          grid-column-start: span 3;

          @media (max-width: 800px) {
            grid-column-start: span 4;
          }
        }

        .top-row {
          min-height: 40rem;

          @media (max-width: 1280px) {
            min-height: 25rem;
          }

          @media (max-width: 1024px) {
            min-height: auto;
          }

          @media (max-width: 800px) {
            grid-column-start: span 4;
          }

          @media (max-width: 550px) {
            //grid-column-start: span 8;
          }
        }
      `}
    >
      {data.footerSettings.addresses.length > 0 &&
        data.footerSettings.addresses.map((address, i) => (
          <div key={i} className="top-row">
            <BlockContent content={address._rawText} />
          </div>
        ))}
      <div
        css={css`
          @media (max-width: 800px) {
            display: flex;
            grid-column-end: 9;
            grid-row-end: 5;
            padding-bottom: 0.4rem;
          }
        `}
      >
        <div
          css={css`
            display: inline-block;
            width: 17.4rem;
            height: 5.4rem;

            @media (max-width: 800px) {
              width: 10.2rem;
              height: 3.2rem;
              padding-top: 0;
              margin-top: auto;
              margin-left: auto;
            }
          `}
        >
          <Logo url="/" invert={true} />
        </div>
      </div>
      <nav
        css={css`
          grid-column: 7 / span 3 !important;

          @media (max-width: 800px) {
            grid-column: 1 / span 4 !important;
            grid-row-start: 4;
            margin-top: -1.2rem;
          }

          @media (max-width: 550px) {
            grid-row-start: auto;
            margin-top: var(--margin6);
          }
        `}
      >
        <BlockContent content={data.footerSettings._rawLinks} />
      </nav>
      <div
        css={css`
          grid-column: 10 / span 3 !important;

          @media (max-width: 800px) {
            grid-column: 1 / span 4 !important;
            margin-top: 3.2rem;
          }
        `}
      >
        <SocialButtons
          invert={true}
          instagramURL={data.footerSettings.instagramUrl}
          twitterURL={data.footerSettings.twitterUrl}
          facebookURL={data.footerSettings.facebookUrl}
          linkedInURL={data.footerSettings.linkedInUrl}
        />
      </div>
    </footer>
  );
}
