import React, { useContext, useLayoutEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Global, css } from '@emotion/react';
import { ThemeContext } from '../components/theme-provider';
import * as normalize from '../styles/normalize.css';
import * as global from '../styles/global.css';
import FadeTransition from '../components/fade-transition';
import SEO from '../components/seo';
import Header from '../components/header';
import Footer from '../components/footer';
import SubscribeModal from '../components/subscribe-modal';

export default function Layout({ children, location }) {
  const [backHeader, setBackHeader] = useState(false);
  const theme = useContext(ThemeContext);

  useLayoutEffect(() => {
    if (location.pathname.includes('/tenet/')) {
      setBackHeader(true);
    } else {
      setBackHeader(false);
    }
  }, [location]);

  return (
    <div
      css={css`
        margin: 0 auto;
      `}
    >
      <SEO />
      <Global
        styles={css`
          ${normalize}
          ${global}
        `}
      />
      <Header
        location={location}
        backHeader={backHeader}
        css={css`
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          z-index: 1000;
        `}
      />
      <FadeTransition location={location}>
        {(theme?.settings?.siteSettings?.showModal && location.pathname !== '/') && <SubscribeModal location={location} />}
        <main>{children}</main>
        <Footer />
      </FadeTransition>
    </div>
  );
}
