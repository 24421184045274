import React, { useContext } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { ThemeContext } from './theme-provider';

export default function Link({ children, to, activeClassName, partiallyActive, ...props }) {
  const theme = useContext(ThemeContext);

  const internal = /^\/(?!\/)/.test(to);

  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...props}
      >
        {children}
      </GatsbyLink>
    );
  }

  return (
    <a href={to} target="_blank" {...props}>
      {children}
    </a>
  );
}
