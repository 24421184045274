import React, { useLayoutEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Mail, Twitter, Facebook, LinkedIn } from './icons';

export default function SocialShare({ className, invert, emailTitle, hash }) {
  const [url, setUrl] = useState();

  hash = hash ? hash : '';

  useLayoutEffect(() => {
    setUrl(window.location.href);
  }, []);

  return (
    <div
      className={className}
      css={css`
        display: flex;

        div,
        a {
          margin-right: 2rem;

          @media (max-width: 550px) {
            margin-right: 1rem;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      `}
    >
      <Mail
        invert={!invert}
        url={`mailto:?subject=BVN${emailTitle ? ` - ${emailTitle}` : ''}&body=${url}${hash}`}
      />
      <Twitter invert={!invert} url={`http://twitter.com/share?url=${url}${hash}`} />
      <Facebook invert={!invert} url={`https://www.facebook.com/sharer/sharer.php?u=${url}${hash}`} />
      <LinkedIn
        invert={!invert}
        url={`http://www.linkedin.com/shareArticle?mini=true&url=${url}${hash}`}
      />
    </div>
  );
}
