import React, { useLayoutEffect, useState, useRef } from 'react';
import { css } from '@emotion/react';
import InlinePlus from './inline-plus';
import { goodColors } from './colors';
import PopupGallery from './popup-gallery';
import PopupImageTitleLinkButton from './popup-image-title-link-button';
import PopupImagePill from './popup-image-pill';

export default function InlineHotspot({
  className,
  popup,
  displayPopup,
  popupIndex,
  onClick,
  closeOnClick,
  children,
}) {
  const [activeTextColor, setActiveTextColor] = useState(goodColors[children[0].length % goodColors.length]);
  const [offsetLeft, setOffsetLeft] = useState(false);

  const spanRef = useRef();

  useLayoutEffect(() => {
    setOffsetLeft(spanRef.current?.getClientRects()[0].x / window.innerWidth > 0.5);
  }, [spanRef]);

  return (
    <span
      css={css`
        position: relative;

        @media (max-width: 800px) {
          position: static;
        }
      `}
    >
      <span
        ref={spanRef}
        className={className ? `type--underline ${className}` : 'type--underline'}
        onClick={(e) => {
          displayPopup ? closeOnClick() : onClick(popupIndex);
        }}
        css={css`
          color: ${displayPopup ? activeTextColor : 'var(--black)'};
          cursor: pointer;

          &:hover {
            color: ${activeTextColor ? activeTextColor : 'var(--black)'};
            > div > div {
              svg {
                circle {
                  fill: ${displayPopup ? 'var(--white)' : 'var(--black)'};
                }

                path {
                  fill: ${displayPopup ? 'var(--black)' : 'var(--white)'};
                }
              }
            }
          }
        `}
      >
        {children}&nbsp;
        <InlinePlus className="inline-hotspot" popupOpen={displayPopup} />
      </span>
      <div
        css={css`
          position: absolute;
          display: ${displayPopup ? 'block' : 'none'};
          top: 7rem;
          left: 0;
          transform: ${offsetLeft ? 'translateX(-100%)' : 'none'};
          z-index: 3;

          @media (max-width: 800px) {
            top: auto;
            left: 50%;
            max-width: calc(100% - var(--gutter));
            transform: translateX(-50%);
            padding-top: var(--margin3);
          }
        `}
      >
        {popup?.type === 'imagesText' && (
          <PopupGallery
            images={popup.images}
            text={popup.text}
            closeOnClick={() => closeOnClick()}
            displayPopup={displayPopup}
          />
        )}
        {popup?.type === 'imageLink' && (
          <PopupImagePill
            image={popup.image}
            text={popup.linkText}
            url={popup.url}
            closeOnClick={() => closeOnClick()}
          />
        )}
        {popup?.type === 'reference' && (
          <PopupImageTitleLinkButton
            image={popup.reference.featuredImage}
            title={popup.reference.title}
            url={`/${popup.reference._type === 'article' ? 'article' : 'project'}/${
              popup.reference.slug.current
            }`}
            closeOnClick={() => closeOnClick()}
          />
        )}
      </div>
    </span>
  );
}
