import React, { useState } from 'react';
import { css } from '@emotion/react';
import { PortableText } from '@portabletext/react';
import Link from './link';
import InlineHotspot from './inline-hotspot';
import PopupShare from './popup-share';

export default function BlockContent({ className, content, footnoteCount }) {
  let popupIndex = -1;
  const [displayPopup, setDisplayPopup] = useState([]);

  const inlineHotspotClick = (i) => {
    const displayPopupArray = [];
    displayPopupArray[i] = true;
    return setDisplayPopup(displayPopupArray);
  };

  const popupCloseOnClick = () => {
    const displayPopupArray = [];
    return setDisplayPopup(displayPopupArray);
  };

  return (
    <>
      {content && (
        <div
          className={className}
          css={css`
            h4, div.type--underline {
              margin-bottom: var(--margin4);
            }

            div.type--underline {
              @media (max-width: 550px) {
                margin-bottom: var(--margin3);
              }
            }

            p + h4, p + div.type--underline {
              margin-top: var(--margin6);
            }

            ul,
            ol {
              list-style: none;
              padding-left: 0;
              margin: var(--gutter) 0;
            }

            ol {
              counter-reset: --counter;

              li {
                position: relative;
                padding-left: var(--gutter);
                counter-increment: --counter;

                &::before {
                  content: counter(--counter) '.';
                  position: absolute;
                  left: 0;
                }
              }
            }

            ul {
              li {
                position: relative;
                padding-left: var(--gutter);

                &::before {
                  position: absolute;
                  display: block;
                  content: '';
                  top: 0.2rem;
                  left: 0;
                  width: 1.2rem;
                  height: 1.2rem;
                  border-radius: 0.6rem;
                  background-color: var(--black);

                  @media (max-width: 800px) {
                    top: 0.3rem;
                    height: 0.9rem;
                    width: 0.9rem;
                  }
                }
              }
            }

            li > ul li {
              &::before {
                position: absolute;
                display: block;
                content: '';
                top: 0.2rem;
                left: 0;
                width: 1.2rem;
                height: 1.2rem;
                background-color: var(--white);
                border: 2px solid var(--black);
                border-radius: 0.6rem;
              }
            }

            li > ol, li > ul {
              margin-top: 2rem;
            }

            &.type--paragraph {
              ol li,
              ul li {
                padding-left: var(--margin5);
              }

              ul li::before {
                top: 0.8rem;
                width: 1.6rem;
                height: 1.6rem;
                border-radius: 0.8rem;

                @media (max-width: 800px) {
                  top: 0.3rem;
                  height: 0.9rem;
                  width: 0.9rem;
                }
              }
            }
          `}
        >
          <PortableText
            className={className}
            value={content}
            components={{
              block: {
                heading: ({ children }) => <h4
                  className="type--underline"
                >{children}</h4>,
                subheading: ({ children }) => <div className="type--underline">{children}</div>,
              },
              marks: {
                link: ({ children, value }) => {
                  return <Link to={value.href}>{children}</Link>;
                },
                underline: ({ children, value }) => {
                  return <span className="type--underline">{children}</span>;
                },
                footnote: ({ children, mark }) => {
                  footnoteCount++;

                  return (
                    <span>
                      {children}
                      <a
                        css={css`
                          font-family: 'Favorit Mono', sans-serif;
                        `}
                        href={`#footnote-${footnoteCount}`}
                      >
                        <sup
                          css={css`
                            font-size: 0.6em;
                          `}
                        >
                          {footnoteCount}
                        </sup>
                      </a>
                    </span>
                  );
                },
                popup: ({ children, value }) => {
                  popupIndex++;

                  return (
                    <InlineHotspot
                      popup={value}
                      displayPopup={displayPopup[popupIndex]}
                      popupIndex={popupIndex}
                      onClick={inlineHotspotClick}
                      closeOnClick={popupCloseOnClick}
                    >
                      {children}
                    </InlineHotspot>
                  );
                },
              },
            }}
          />
        </div>
      )}
    </>
  );
}
