import React, { useState } from 'react';
import { css } from '@emotion/react';
import SanityImage from 'gatsby-plugin-sanity-image';
import ConditionalWrapper from './conditional-wrapper';
import { Save, Detail } from './icons';
import Link from './link';

export default function PeopleCard({ className, url, image, text, saveButton, saved }) {
  const [hover, setHover] = useState(false);

  return (
    <ConditionalWrapper
      condition={url}
      wrapper={(children) => (
        <Link
          className="type--underline-inverse"
          to={url}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {children}
        </Link>
      )}
    >
      <div
        className={className}
        css={css`
          display: inline-block;
        `}
      >
        <div
          css={css`
            position: relative;
            margin-bottom: 1.5rem;

            img {
              display: block;
              max-width: 100%;
              aspect-ratio: 173/190;
              object-fit: cover;
            }
          `}
        >
          <div
            css={css`
              padding-top: ${image ? '0' : '109.8%'};
            `}
          >
            {image?.asset && <SanityImage width={500} height={500}{...image} />}
          </div>
          {saveButton && (
            <Save
              active={saved}
              css={css`
                position: absolute;
                top: 0.8rem;
                right: 0.8rem;
                display: ${hover ? 'block' : saved ? 'block' : 'none'};

                @media (max-width: 550px) {
                  display: block;
                }
              `}
            />
          )}
          {url && (
            <Detail 
            css={css`
              position: absolute;
              top: 0.8rem;
              right: 0.8rem;
              display: block;
              z-index: 1;
              pointer-events: none;
            `}
            />
          )}
        </div>
        <div
          css={css`
            @media (max-width: 800px) {
              font-family: ${url ? "'Favorit Lining', serif" : "'Favorit', serif"};
            }
          `}
        >
          {text}
        </div>
      </div>
    </ConditionalWrapper>
  );
}
