import React, { useState } from 'react';
import { css } from '@emotion/react';
import { Plus, Minus } from './icons';

export default function InlinePlus({ className, popupOpen }) {
  return (
    <div
      className={className}
      css={css`
        position: relative;
        display: inline-block;
        top: -0.2rem;
        width: 2.4rem;
        padding-left: 0.2rem;

        @media (max-width: 800px) {
          width: 2.3rem;
          padding-left: 0;
        }

        @media (max-width: 550px) {
          top: 0;
          height: 1.3rem;
          width: 2rem;
        }
      `}
    >
      <Plus
        mini={true}
        css={css`
          display: ${popupOpen ? 'none' : 'block'};
        `}
      />
      <Minus
        mini={true}
        active={true}
        css={css`
          display: ${popupOpen ? 'block' : 'none'};
        `}
      />
    </div>
  );
}
