import React, { useState } from 'react';
import { css } from '@emotion/react';
import IconArrowRight from '../svg/arrow-right.svg';
import BlockContent from './block-content';
import { Close } from './icons';
import Link from './link';

export default function CaptionCard({
  className,
  monoHeading,
  text,
  linkText,
  url,
  urlOnClick,
  hideButtonText,
  hideButtonOnClick,
  closeButton,
  closeButtonOnClick,
  position,
  indent,
  invert,
}) {
  const black = invert ? 'var(--white)' : 'var(--black)';
  const white = invert ? 'var(--black)' : 'var(--white)';

  const indents = ['var(--margin8)', 'var(--gutter)', 'calc(-1 * var(--gutter) / 2)', '-12.7rem'];
  const positions = [
    {
      top: 'calc(var(--gutter) * -1)',
      right: 'auto',
      bottom: 'auto',
      left: indents[indent],
    },
    {
      top: 'calc(var(--gutter) * -1)',
      right: indents[indent],
      bottom: 'auto',
      left: 'auto',
    },
    {
      top: 'auto',
      right: indents[indent],
      bottom: 'calc(var(--gutter) * -1)',
      left: 'auto',
    },
    {
      top: 'auto',
      right: 'auto',
      bottom: 'calc(var(--gutter) * -1)',
      left: indents[indent],
    },
  ];

  const indentsTablet = [
    'var(--gutterHalf)',
    'calc(-1 * var(--gutter) / 2)',
    'calc(-1 * var(--gutter) / 2)',
    'calc(-1 * var(--gutter) / 2)',
  ];
  const positionsTablet = [
    {
      top: 'calc(var(--gutter) * -1)',
      right: 'auto',
      bottom: 'auto',
      left: indentsTablet[indent],
    },
    {
      top: 'calc(var(--gutter) * -1)',
      right: indentsTablet[indent],
      bottom: 'auto',
      left: 'auto',
    },
    {
      top: 'auto',
      right: indentsTablet[indent],
      bottom: 'calc(var(--gutter) * -1)',
      left: 'auto',
    },
    {
      top: 'auto',
      right: 'auto',
      bottom: 'calc(var(--gutter) * -1)',
      left: indentsTablet[indent],
    },
  ];

  return (
    <div
      className={`type--body ${className ? className : ''}`}
      css={css`
        position: absolute;
        top: ${positions[position] ? positions[position].top : 'auto'};
        right: ${positions[position] ? positions[position].right : 'auto'};
        bottom: ${positions[position] ? positions[position].bottom : 'auto'};
        left: ${positions[position] ? positions[position].left : 'auto'};
        width: 29.5rem;
        color: ${white};
        padding: 2.1rem 2rem 2.3rem;
        background-color: ${black};
        border: ${invert ? '3px solid var(--black)' : 'transparent'};
        filter: var(--dropShadow);

        @media (max-width: 1024px) {
          top: ${positionsTablet[position] ? positionsTablet[position].top : 'auto'};
          right: ${positionsTablet[position] ? positionsTablet[position].right : 'auto'};
          bottom: ${positionsTablet[position] ? positionsTablet[position].bottom : 'auto'};
          left: ${positionsTablet[position] ? positionsTablet[position].left : 'auto'};
          width: 21.7rem;
          padding: 1.1rem 1.4rem 1.2rem;
        }

        @media (max-width: 550px) {
          position: relative;
          z-index: 1;
          top: auto;
          right: auto;
          bottom: auto;
          left: auto;
          margin-top: calc(-1 * var(--gutterHalf));
          margin-bottom: calc(-1 * var(--gutterHalf));
        }
      `}
    >
      {monoHeading && (
        <div
          className="type--mono-heading"
          css={css`
            margin-bottom: 1.2rem;
          `}
        >
          {monoHeading}
        </div>
      )}
      {text && <>{typeof text === 'string' ? text : <BlockContent content={text} />}</>}
      {(url || linkText) && (
        <Link
          className="type--body-small"
          to={url}
          onClick={urlOnClick}
          css={css`
            display: block;
            color: ${white} !important;
            margin-top: 3.6rem;

            @media (max-width: 800px) {
              margin-top: 1.8rem;
            }

            &:hover {
              font-family: 'Favorit Lining', serif;

              span {
                margin-left: 1.3rem;
              }
            }
          `}
        >
          {linkText}
          <span
            css={css`
              margin-left: 0.7rem;
              transition: margin-left 0.4s var(--curve);

              @media (max-width: 800px) {
                margin-left: 0.5rem;
              }
            `}
          >
            <IconArrowRight
              css={css`
                width: 1.2rem;
                height: 1rem;

                @media (max-width: 800px) {
                  width: 0.9rem;
                  height: 0.8rem;
                }

                path {
                  fill: ${white};
                }
              `}
            />
          </span>
        </Link>
      )}
      {(hideButtonText || hideButtonOnClick) && (
        <div
          className="type--body-small type--underline type--underline-hover-state"
          css={css`
            display: inline-block;
            margin-top: 3.6rem;
            cursor: pointer;

            @media (max-width: 800px) {
              margin-top: 1.8rem;
            }
          `}
          onClick={hideButtonOnClick}
        >
          {hideButtonText}
        </div>
      )}
      {(closeButton || closeButtonOnClick) && (
        <Close
          hover={true}
          onClick={closeButtonOnClick}
          css={css`
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(50%, -50%);
          `}
        />
      )}
    </div>
  );
}
