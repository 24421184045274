import React from 'react';
import { ThemeProvider } from './src/components/theme-provider';

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider>{element}</ThemeProvider>;
};

export const shouldUpdateScroll = (e) => {
  if (
    e.routerProps.location.hash === '' &&
    e.prevRouterProps?.location.pathname !== window.location.pathname
  ) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  return false;
};
