import React, { useContext, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import SanityImage from 'gatsby-plugin-sanity-image';
import { ThemeContext } from '../components/theme-provider';
import ConditionalWrapper from './conditional-wrapper';
import { Save } from './icons';
import Link from './link';
import LinkOutBox from './link-out-box';


export default function Card({
  className,
  url,
  image,
  imageCrop,
  imageOverlay,
  monoText,
  text,
  hoverText,
  saveButton,
  saved,
  external,
  externalTitle,
  workRatio,
  hero,
}) {
  const [hover, setHover] = useState(false);
  const [title, setTitle] = useState(text);

  const theme = useContext(ThemeContext);

  let isSafari = false;
  if (typeof navigator !== 'undefined')
    isSafari = navigator.userAgent.includes('Safari');

  useEffect(() => {
    if (window) {
      if (isSafari && window.innerWidth < 1200 && text.length > 70) {
        setTitle(`${text.substring(0, 70)}...`);
      } else {
        setTitle(text);
      }
    }
  }, [text]);

  imageOverlay = false;

  return (
    <ConditionalWrapper
      condition={url}
      wrapper={(children) => (
        <Link className={`${className} type--underline-inverse`} to={url}>
          {children}
        </Link>
      )}
    >
      <div
        className={!url && className}
        css={css`
          display: inline-block;
        `}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div
          css={css`
            position: relative;
            margin-bottom: 1.5rem;

            img {
              display: block;
              max-width: 100%;
              ${imageOverlay &&
                `
                  filter: grayScale(100);
                `
              }
              ${imageCrop &&
              `
                aspect-ratio: ${hero ? '9/5' : '4/3'};
                object-fit: cover;
              `}
              ${(workRatio < 1) && 
              `
                height: calc(
                  (8 * var(--gridColumn) + 7 * var(--gutter)) 
                  /
                  (813 / 457)
                );
                object-fit: cover;

                @media (max-width: 1024px) {
                  height: calc(
                    (8 * var(--gridColumnTablet) + 7 * var(--gutter)) 
                    /
                    (813 / 457)
                  );
                }

                @media (max-width: 800px) {
                  height: auto;
                }
              `}
            }
          `}
        >
          {!workRatio && image?.asset && <SanityImage width={660} {...image} />}
          {workRatio && image?.asset && <SanityImage
            width={(hero) ? 813 : (workRatio > 1) ? 660 : 660}
            height={(hero) ? 457 : (workRatio > 1) ? 486 : 777}
            {...image}
          />}
          {imageOverlay && (
            <div
              css={css`
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: ${imageOverlay};
                mix-blend-mode: multiply;
              `}
            ></div>
          )}
          {hoverText && (
            <div
              className="type--body type--underline-override"
              css={css`
                position: absolute;
                top: 50%;
                left: 50%;
                display: ${hover ? 'flex' : 'none'};
                justify-content: center;
                align-items: center;
                width: 22.8rem;
                max-width: 75%;
                text-align: center;
                background-color: var(--white);
                padding: 1.2rem;
                filter: var(--dropShadow);
                transform: translate(-50%, -51%);

                @media (max-width: 1280px) {
                  width: 24.8rem;
                  height: auto;
                }
                
                @media (max-width: 800px) {
                  align-items: center;
                  display: flex;
                }
              `}
            >
              {hoverText}
            </div>
          )}
          {saveButton && (
            <Save
              css={css`
                position: absolute;
                top: 0.8rem;
                right: 0.8rem;
                display: ${hover ? 'block' : theme.collection.has(saveButton) ? 'block' : 'none'};

                @media (max-width: 550px) {
                  display: block;
                }
              `}
              active={theme.collection.has(saveButton)}
              onClick={(e) => {
                e.preventDefault();
                if (theme.collection.has(saveButton)) {
                  const newCollection = theme.collection;
                  newCollection.delete(saveButton);

                  theme.setCollection(new Set(newCollection));
                } else {
                  theme.setCollection(new Set([...theme.collection, saveButton]));
                }
              }}
            />
          )}
          {external && (
            <LinkOutBox
              title={externalTitle}
              css={css`
                position: absolute;
                top: 0;
                right: 0;
                display: block;
                pointer-events: none;
              `}
            />
          )}
        </div>
        <div
          className="type--mono-heading"
          css={css`
            margin-bottom: 0.9rem;

            @media (max-width: 500px) {
              margin-bottom: 1.1rem;
            }
          `}
        >
          {monoText}
        </div>
        <div
          className="type--heading-five"
          css={css`
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;

            @media (max-width: 800px) {
              ${(isSafari) ? 'display: block;' : ''}
              font-family: ${url ? "'Favorit Lining', serif" : "'Favorit', serif"};
            }
          `}
        >
          {title}
        </div>
      </div>
    </ConditionalWrapper>
  );
}
