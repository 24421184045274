import React, { useState } from 'react';

const colors = [
  'rgba(85, 224, 226, 1)',
  'rgba(134, 128, 199, 1)',
  'rgba(37, 183, 101, 1)',
  'rgba(165, 244, 110, 1)',
  'rgba(232, 252, 116, 1)',
  'rgba(254, 224, 57, 1)',
  'rgba(255, 178, 58, 1)',
  'rgba(245, 115, 92, 1)',
  'rgba(170, 65, 73, 1)',
  'rgba(63, 112, 171, 1)',
  'rgba(34, 163, 239, 1)',
];

const goodColors = [
  'rgba(85, 224, 226, 1)',
  'rgba(134, 128, 199, 1)',
  'rgba(37, 183, 101, 1)',
  'rgba(63, 112, 171, 1)',
  'rgba(34, 163, 239, 1)',
];

function randomColor() {
  return colors[Math.floor(Math.random() * colors.length)];
}

function randomGoodColor() {
  return goodColors[Math.floor(Math.random() * goodColors.length)];
}

export { colors, goodColors, randomColor, randomGoodColor };
