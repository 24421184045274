import React from 'react';
import { css } from '@emotion/react';
import ConditionalWrapper from './conditional-wrapper';

export default function Grid({ className, columns, feed, children }) {
  return (
    <div
      className={className}
      css={css`
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: ${feed ? 'var(--margin6)' : 'var(--gutter)'} var(--gutter);
        padding-bottom: ${feed ? 'var(--margin5)' : '0'};

        @media (max-width: 800px) {
          gap: ${feed ? 'var(--margin6)' : 'var(--gutter)'} var(--gutterHalf);
        }

        @media (max-width: 550px) {
          grid-template-columns: repeat(8, 1fr);
          gap: ${feed ? 'var(--margin5)' : 'var(--gutter)'} var(--gutterHalf);
        }
      `}
    >
      <ConditionalWrapper
        condition={columns}
        wrapper={(children) => (
          <div
            css={css`
              grid-column: calc((12 - ${columns}) / 2 + 1) / span ${columns};

              @media (max-width: 800px) {
                grid-column: ${columns === 10
                  ? '1 / span 12'
                  : `calc((12 - ${columns}) / 2 + 1) / span ${columns}`};
              }

              @media (max-width: 550px) {
                grid-column: 1 / span 8;
              }
            `}
          >
            {children}
          </div>
        )}
      >
        {children}
      </ConditionalWrapper>
    </div>
  );
}
