import React, { useContext } from 'react';
import { TransitionGroup, Transition } from 'react-transition-group';
import { ThemeContext } from '../components/theme-provider';

export default function FadeTransition({ children, location }) {
  const theme = useContext(ThemeContext);

  const duration = 600;

  const transitionStyles = {
    entering: {
      position: 'absolute',
      opacity: 0,
    },
    entered: {
      transition: `opacity ${duration}ms var(--curve)`,
      opacity: 1,
    },
    exiting: {
      transition: `opacity ${duration}ms var(--curve)`,
      opacity: 0,
    },
  };

  return (
    <TransitionGroup component={null}>
      <Transition
        key={location.pathname}
        timeout={{
          enter: duration,
          exit: duration,
        }}
        onExited={() => theme.setHeaderHide(false)}
      >
        {(state) => (
          <div
            style={{
              ...transitionStyles[state],
            }}
          >
            {children}
          </div>
        )}
      </Transition>
    </TransitionGroup>
  );
}
