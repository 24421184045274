import React, { useLayoutEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Twitter, Facebook, Instagram, LinkedIn } from './icons';

export default function SocialButtons({ className, invert, twitterURL, facebookURL, instagramURL, linkedInURL }) {
  return (
    <div
      className={className}
      css={css`
        display: flex;

        > a {
          margin-right: 0.9rem;

          &:last-child {
            margin-right: 0;
          }
        }
      `}
    >
      {instagramURL &&
        <Instagram
          invert={invert}
          url={instagramURL}
        />
      }
      {twitterURL && 
        <Twitter 
          invert={invert}
          url={twitterURL}
        />
      }
      {facebookURL &&
        <Facebook
          invert={invert}
          url={facebookURL}
        />
      }
      {linkedInURL &&
        <LinkedIn
          invert={invert}
          url={linkedInURL}
        />
      }
    </div>
  );
}
